import React from 'react'
import styles from "./AreaTabs.module.css"
function AreaTabs() {
    return (
        <>
            <div className={styles.areaTabs}>
                <div className={styles.tabs}>
                    <h1>
                        Our areas of expertise
                    </h1>
                    <div className={styles.cards}>
                        <div className={`${styles.card} ${styles.active}`} >
                            <h1>Research Development</h1>
                        </div>
                        <div className={styles.card}>
                            <h1>Production</h1>
                        </div>
                        <div className={styles.card}>
                            <h1>Product Safety</h1>
                        </div>
                        <div className={styles.card}>
                            <h1>Quality Assurance</h1>
                        </div>
                    </div>

                    <div className={styles.content}>
                        <img src='/assets/shapes/Group 13 (1).png' />
                        <div className={styles.para}>
                            <p>Innovation lies at the core of our European-wide specialist R&D team. With diverse formulation expertise and decades of industry knowledge, they continually seek fresh and innovative ways to meet the performance needs of customers. This is backed up by our product development team, which is dedicated to refining bottle form and function to meet industry standards and cost-constraints.</p>
                        </div>
                    </div>

                    <div className={styles.labImg}>
                        <img src='/assets/images/close-up-researcher-holding-glassware 1.png' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AreaTabs