import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Business from '../../components/homeComponets/business/Business'
import Competences from '../../components/homeComponets/competences/Competences'
import GlobalReach from '../../components/homeComponets/globalReach/GlobalReach'
import Navbar from '../../components/header/Navbar'
import Home from '../../components/homeComponets/home/Home'
import styles from "./HomePage.module.css"

function HomePage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Goodfamily | Home</title>
            </Helmet>
            <div className={styles.homepage}>
                <Navbar />
                <div className={styles.homeSection}>
                    <Home />
                </div>
                <Business />
                <GlobalReach />
                <Competences />
            </div>
        </>
    )
}

export default HomePage