import React, { useEffect } from 'react'
import Navbar from '../../components/header/Navbar'
import BannerContent from "../../components/bannerContent/BannerContent"
import ContactForm from '../../components/contactForm/ContactForm'
import styles from "./ContactPage.module.css"
function ContactPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Navbar />
            <div className={styles.banner}>
                <BannerContent details={"We are always just a phone call or email away. If you would like us to get back to you, simply fill out the form and we will be in touch as soon as possible."} heading={"Get in touch"} mainHead={"CONTACT"} />
            </div>
            <div className={styles.contactForm}>
                <ContactForm />
            </div>
        </>
    )
}

export default ContactPage