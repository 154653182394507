import React from 'react'
import styles from "./BannnerContent.module.css"
function BannerContent({ mainHead, heading, details }) {
    return (
        <>
            <div className={styles.bannerContent}>
                <h3>{mainHead}</h3>
                <h1>{heading}</h1>
                <span>{details}</span>
            </div>
        </>
    )
}

export default BannerContent