import React from 'react'
import { useRoutes } from 'react-router-dom';
import AboutusPage from './pages/aboutusPage/AboutusPage';
import BusinessPage from './pages/businessPage/BusinessPage';
import CareerPage from './pages/careerPage/CareerPage';
import ContactPage from './pages/contactPage/ContactPage';
import HomePage from './pages/home/HomePage';
import NewsPage from './pages/newsPage/NewsPage';
import ProductsPage from './pages/productsPage/ProductsPage';

function Router() {
    const routes = useRoutes([
        {
            path: "/",
            element: <HomePage />
        },
        {
            path: "/business",
            element: <BusinessPage />
        },
        {
            path: "/products",
            element: <ProductsPage />
        },
        {
            path: "/contact",
            element: <ContactPage />
        },
        {
            path: "/news",
            element: <NewsPage />
        },
        {
            path: "/career",
            element: <CareerPage />
        },
        {
            path: "/aboutus",
            element: <AboutusPage />
        },
    ])
    return routes;
}

export default Router