import { Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import "./Footer.css"
function Footer() {
    const navigate = useNavigate();

    return (
        <>
            <Stack sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>
                <div className='footer-section'>
                    <div className='content'>
                        <h3>CONTACT</h3>
                        <h1>Feel free to contact us and ask anything</h1>
                        <button onClick={() => navigate("/contact")}>CONTACT US</button>
                    </div>
                    <div className='picture'>
                        <img src='/assets/images/Group-43.png' />
                    </div>
                </div>

                <div className='footer-social'>
                    <span>© 2023 – GOODFAMILY</span>
                    <div className='center'>
                        <span>IMPRINT&nbsp;&nbsp;&nbsp; |</span>
                        <span>DATA PROTECTION</span>
                    </div>
                    <div className='icons'>
                        <img src='/assets/images/icon-facebook.svg.png' />
                        <img src='/assets/images/icon-linkedin.svg.png' />
                    </div>
                </div>
            </Stack>

            {/* MOBILE */}

            <Stack sx={{ display: { lg: "none", md: "none", sm: "block", xs: "block" } }}>
                <div style={{
                    "display": "flex",
                    "flexDirection": "column",
                    "alignItems": "center",
                    "justifyContent": "space-evenly",
                    "padding": "80px 20px",
                    "background": "linear-gradient(180deg, #fbf8eb 0%, #ffe992 100%)"
                }}>
                    <img style={{
                        "width": "209px",
                    }} src='/assets/images/Group-43.png' />

                    <div style={{
                        // "maxWidth": "540px",
                        "display": "flex",
                        "flexDirection": "column",
                        "gap": "15px",
                        marginTop: "20px",
                        alignItems: "center"
                    }}>
                        <h3 style={{
                            "fontStyle": "normal",
                            "fontWeight": "700",
                            "fontSize": "18px",
                            "lineHeight": "25px",
                            "color": "#ff0558",
                            textAlign: "center"
                        }}>CONTACT</h3>
                        <h1 style={{
                            "fontStyle": "normal",
                            "fontWeight": "700",
                            "fontSize": "34px",
                            "lineHeight": "40px",
                            textAlign: "center",
                            // width: "250px"
                        }}>Feel free to contact us and ask anything</h1>
                        <button style={{
                            "width": "182.45px",
                            "height": "44.8px",
                            "background": "#ff0558",
                            "borderRadius": "22px",
                            "fontStyle": "normal",
                            "fontWeight": "600",
                            "fontSize": "14px",
                            "lineHeight": "17px",
                            "textAlign": "center",
                            "textTransform": "uppercase",
                            "color": "#ffffff",
                            "border": "none",
                            "cursor": "pointer"
                        }}>CONTACT US</button>
                    </div>
                </div>

                <div style={{
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "space-between",
                    "padding": "10px 20px",
                    flexDirection: "column",
                    gap: "20px",
                    borderBottom: "5px solid #ff0558"
                }}>
                    <span style={{
                        "fontStyle": "normal",
                        "fontWeight": "500",
                        "fontSize": "12px",
                        "lineHeight": "17px"
                    }}>IMPRINT</span>
                    <span style={{
                        "fontStyle": "normal",
                        "fontWeight": "500",
                        "fontSize": "12px",
                        "lineHeight": "17px"
                    }}>DATA PROTECTION</span>

                    <div style={{
                        "display": "flex",
                        "gap": "20px"
                    }}>
                        <img style={{ width: "25px" }} src='/assets/images/icon-facebook.svg.png' />
                        <img style={{ width: "25px" }} src='/assets/images/icon-linkedin.svg.png' />
                    </div>
                    <span style={{
                        "fontStyle": "normal",
                        "fontWeight": "500",
                        "fontSize": "12px",
                        "lineHeight": "17px"
                    }}>© 2023 – GOODFAMILY</span>
                </div>
            </Stack>
        </>
    )
}

export default Footer