import React, { useEffect } from 'react'
import Navbar from '../../components/header/Navbar';
import BannerContent from '../../components/bannerContent/BannerContent'
import CompanyProfile from '../../components/aboutus/companyProfile/CompanyProfile';

import styles from "./AboutusPage.module.css";
import CompanyDetails from '../../components/aboutus/companyDetails/CompanyDetails';
import Policy from '../../components/aboutus/policy/Policy';
import Certification from '../../components/aboutus/certification/Certification';
import Sustainablity from '../../components/aboutus/sustainablity/Sustainablity';

function AboutusPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Navbar />
            <div className={styles.aboutus}>
                <BannerContent mainHead={"ABOUT US"} heading={"We are Goodfamily"} details={"Thanks to the experience and commitment of our teams, from design to manufacturing, we are focused on offering our customers the best and most innovative solutions to make them even more successful."} />
            </div>
            <CompanyProfile />
            <CompanyDetails />
            <Policy />
            <Certification />
            <Sustainablity />
        </>
    )
}

export default AboutusPage