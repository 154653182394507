import React from 'react'
import Download from '../../download/Download'
import styles from './Policy.module.css'

function Policy() {
    return (
        <>
            <div className={styles.policy}>
                <div className={styles.header}>
                    <h3>CORPORATE POLICY</h3>
                    <h1>Our corporate policy</h1>
                    <span>Our policy forms an integral part of our corporate responsibility. It sets out how we want to conduct our business, what we expect of ourselves and of our partners across Europe.</span>
                </div>

                <div className={styles.images}>
                    <img src='/assets/images/people.png' />
                    <Download heading={"CORPORATE POLICY"} />
                </div>
            </div>
        </>
    )
}

export default Policy