import React from 'react'
import styles from './Certification.module.css'
function Certification() {
    return (
        <>
            <div className={styles.certification}>
                <div className={styles.header}>
                    <h3>CERTIFICATION</h3>
                    <h1>Certified quality</h1>
                    <span>It is important for us to completely eliminate or continuously minimise the environmental impact caused by our products and processes within the scope of the means available. This goal requires more than compliance with legal requirements. The existing management system certifications and the EMAS validation of our site prove that our Management and employees are committed to a responsible use of resources. The obligations arising from the certifications enable us to identify ourselves with the company in the long term and to pursue the goals we have set ourselves together.</span>
                </div>

                <div className={styles.status}>
                    <div>
                        <img src='/assets/shapes/dot1.png' />
                        <span>Available</span>
                    </div>
                    <div>
                        <img src='/assets/shapes/dot2.png' />
                        <span>In Progress</span>
                    </div>
                </div>


                <div className={styles.certificates}>
                    <h4>CERTIFICATES</h4>
                    <div className={styles.cards}>
                        <div className={styles.first}>
                            <div className={styles.card}>
                                <h3>ENVIRONMENT</h3>
                                <img src='/assets/logo/env.png' />
                            </div>
                            <div className={styles.card}>
                                <h3>QUALITY</h3>
                                <img src='/assets/logo/qulity.png' />
                            </div>
                            <div className={styles.card}>
                                <h3>HYGIENE</h3>
                                <img src='/assets/logo/hygine.png' />
                            </div>
                        </div>

                        <div className={styles.last}>
                            <div className={styles.card}>
                                <h3>PRODUCTS
                                    CERTIFICATIONS</h3>
                                <img src='/assets/logo/productCert.png' />
                            </div>
                            <div className={styles.card}>
                                <h3>CSR</h3>
                                <img src='/assets/logo/csr.png' />
                            </div>
                            <div className={styles.card}>
                                <h3>SUSTAINABILITY</h3>
                                <img src='/assets/logo/sustain.png' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Certification