import { Grid } from '@mui/material'
import React from 'react'
import styles from "./BusinessContent.module.css"
function BusinessContent() {

    const data = [
        {
            heading: "Private Label",
            content: "In order to meet consumer expectations, our formulas are elaborated to offer you high-performance products. Our sustainable approach drives us to develop environmentally friendly and high quality packaging. Our excellent supplier relationships also enable us to offer the latest innovations at the fairest price.",
            image: "/assets/images/flasche_private_label_en 1.png",
            details: [
                {
                    head: "Flexible",
                    paragraph: "65 formats"
                },
                {
                    head: "Experience",
                    paragraph: "100 years of industry experience"
                },
                {
                    head: "Quality",
                    paragraph: "Product and process"
                },
                {
                    head: "Commitment",
                    paragraph: "Certified standards"
                },
            ]
        },
        {
            heading: "Contract Manufacturing",
            content: "Global brand owners trust our capability to deliver best-in-class quality, market responsiveness and cost control at every stage.",
            image: "/assets/images/flasche_contract_manufacturing_en 2.png",
            details: [
                {
                    head: "Partnership",
                    paragraph: "Mutual Success"
                },
                {
                    head: "Trust",
                    paragraph: "100% Transparency"
                },
                {
                    head: "Agility",
                    paragraph: "Fast project handling"
                },
                {
                    head: "Performance",
                    paragraph: "High capacity & product variety"
                },
            ]
        },
        {
            heading: "Our own brands",
            content: "Based on our know-how and experience in the field of detergents and enriched by our customer partnerships, we also produce and distribute our own brands of household and cleaning products.",
            image: "/assets/images/flasche_own_brand_en 1.png",
            details: [
                {
                    head: "Sustainability",
                    paragraph: "Environmentally conscious use of resources"
                },
                {
                    head: "Innovation",
                    paragraph: "A sure feel for trends"
                },
                {
                    head: "Customers",
                    paragraph: "100% satisfaction"
                },
            ]
        },

    ]

    return (
        <>
            <div className={styles.businessDetails}>
                {
                    data.map((item, index) => {
                        const isEven = index % 2 === 0;
                        return (
                            <>
                                {isEven ? (
                                    <div className={styles.businessContent} style={{ borderBottom: `${index <= 1 ? "10px solid #FF0659" : ""}` }}>
                                        <div className={styles.head}>
                                            <h1>{item.heading}</h1>
                                            <span>{item.content}</span>
                                        </div>
                                        <div className={styles.imageSection}>
                                            <div className={styles.bottleImage}>
                                                <img src={item.image} />
                                            </div>

                                            <Grid container spacing={2} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", maxWidth: "600px" }}>
                                                {item.details.map((card) => {
                                                    return (

                                                        <Grid item md={6} sm={6} xs={6}>
                                                            <div className={styles.shape}>
                                                                <img src='/assets/shapes/round.svg' />
                                                                <h1>{card.head}</h1>
                                                                <p>{card.paragraph}</p>
                                                            </div>
                                                        </Grid>
                                                    )
                                                })}

                                            </Grid>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.businessContent} style={{ borderBottom: `${index <= 1 ? "10px solid #FF0659" : ""}` }}>
                                        <div className={styles.head}>
                                            <h1>{item.heading}</h1>
                                            <span>{item.content}</span>
                                        </div>
                                        <div className={styles.imageSection}>
                                            <Grid container spacing={2} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", maxWidth: "600px" }}>
                                                {item.details.map((card) => {
                                                    return (

                                                        <Grid item md={6} sm={6} xs={6}>
                                                            <div className={styles.shape}>
                                                                <img src='/assets/shapes/round.svg' />
                                                                <h1>{card.head}</h1>
                                                                <p>{card.paragraph}</p>
                                                            </div>
                                                        </Grid>
                                                    )
                                                })}

                                            </Grid>

                                            <div className={styles.bottleImage} >
                                                <img src={item.image} style={{ maxWidth: "350px", minWidth: "250px" }} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}

export default BusinessContent