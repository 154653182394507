import React from 'react'
import styles from "./CareerWithUs.module.css"

function CareerWithUs() {
    const data = [
        {
            img: "/assets/shapes/icon_locker 1.png",
            heading: "Be open minded",
            details: "Talk to your colleagues and share your ideas, but also listen. Your experience and professional competence will benefit from the exchange - one of the keys to your success."
        },
        {
            img: "/assets/shapes/icon_idea 1.png",
            heading: "Be creative",
            details: "Make targeted use of your knowledge and experience. Our customers expect the best results possible. Take up the challenge by developing creative and sustainable solutions to meet their requirements."
        },
        {
            img: "/assets/shapes/icon_mountain 1.png",
            heading: "Be ambitious",
            details: "Expect more of yourself and don’t settle for what you have already achieved. Have fun facing new challenges every day. You will grow personally and professionally and advance your career. SOPRONEM offers you the best conditions for this."
        },
        {
            img: "/assets/shapes/icon_think 1.png",
            heading: "Be innovative",
            details: "Have the courage to be a bit unconventional and to break new ground in problem solving. Do not hesitate to share your ideas and give your team important impulses."
        },
        {
            img: "/assets/shapes/icon_team 1.png",
            heading: "Be team oriented",
            details: "Our cooperation is characterised by appreciation, transparency and constructive communication. We want to be successful together and rely on the performance and commitment of our colleagues. As a new member of the team you will be able to make an important contribution to this."
        },
    ]
    return (
        <>
            <div className={styles.career}>
                <div className={styles.header}>
                    <h3>WORK WITH US</h3>
                    <h1>Share our values</h1>
                    <span>Are you ambitious and would like to join a successful company that shares your values? Then you should join us. Let’s work passionately together to make our customers even more successful and ultimately make an important contribution to the protection of our environment.</span>
                </div>

                <div className={styles.cards}>
                    {
                        data.map((item, index) => (
                            <div className={styles.card} style={{ borderTop: `${index == 0 && "15px solid #D0D0D0"}` }}>
                                <img src={item.img} />
                                <div className={styles.cardContent}>
                                    <h1>{item.heading}</h1>
                                    <span>{item.details}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default CareerWithUs