import React, { useEffect } from 'react'
import Navbar from "../../components/header/Navbar"
import NewsBanner from '../../components/newsBanner/NewsBanner'
import NewsContent from '../../components/newsContent/NewsContent'
import styles from "./NewsPage.module.css"
function NewsPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Navbar />
            <div className={styles.banner}>
                <div className={styles.bannerContent}>
                    <NewsBanner />
                </div>

            </div>
            <NewsContent />
        </>
    )
}

export default NewsPage