
import { useEffect, useRef, useState } from 'react'
import { Close, Menu } from '@mui/icons-material'
import React from 'react'

import "./Navbar.css"
import { Link, useLocation, useNavigate } from 'react-router-dom'


function Navbar() {
    const navRef = useRef();
    const navigate = useNavigate()
    const location = useLocation();
    const showNavBar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }
    return (
        <>
            <header>
                <a href='/'>
                    <img src='/assets/images/logo 1.png' />
                </a>
                <button className="nav-btn" onClick={showNavBar}>
                    <Menu />
                </button>
                <nav ref={navRef}>
                    <a className={location.pathname === '/' ? 'active' : ''} onClick={() => { navigate("/"); showNavBar(); }}>Home</a>
                    <a className={location.pathname === '/business' ? 'active' : ''} onClick={() => { navigate("/business"); showNavBar(); }}>Our Business</a>
                    <a className={location.pathname === '/products' ? 'active' : ''} onClick={() => { navigate("/products"); showNavBar() }}>Products</a>
                    <a className={location.pathname === '/aboutus' ? 'active' : ''} onClick={() => { navigate("/aboutus"); showNavBar() }}>About Us</a>
                    <a className={location.pathname === '/career' ? 'active' : ''} onClick={() => { navigate("/career"); showNavBar() }}>Career</a>
                    <a className={location.pathname === '/news' ? 'active' : ''} onClick={() => { navigate("/news"); showNavBar() }}>News</a>
                    <a className={location.pathname === '/contact' ? 'active' : ''} onClick={() => { navigate("/contact"); showNavBar() }}>Contact</a>
                    <button
                        className="nav-btn nav-close-btn"
                        onClick={showNavBar}>
                        <Close />
                    </button>
                </nav>
            </header>
        </>
    )
}

export default Navbar