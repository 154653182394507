import { Button, Card, CardActions, CardContent, CardMedia, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import AOS from "aos"
import "aos/dist/aos.css"
import "./Business.css"
import { useNavigate } from 'react-router-dom'
function Business() {
    const navigate = useNavigate();
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    const cardData = [
        {
            image: "/assets/images/flasche_private_label_en 1.png",
            content: "From individual products to complete product ranges, we design, develop and produce private labels for our customers in the European retail market.",
            button: "DISCOVER MORE"
        },
        {
            image: "/assets/images/flasche_contract_manufacturing_en 2.png",
            content: "Global brand owners trust our capability to deliver best-in-class quality, market responsiveness and cost control at every stage.",
            button: "DISCOVER MORE"
        },
        {
            image: "/assets/images/flasche_own_brand_en 1.png",
            content: "Based on our know-how and experience in the field of detergents and enriched by our customer partnerships, we also produce and distribute our own brands of household and cleaning products.",
            button: "DISCOVER MORE"
        },
    ]
    return (
        <>
            <Stack sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>

                <div className='business-section'>
                    <div className='business-header'>
                        <h4>Our business</h4>
                        <h2>Meet your expectations</h2>
                        <p>We develop and produce private labels, our own brands and offer companies customized solutions from simple contract filling to full service.</p>
                    </div>
                    <div className='business-card'>
                        {cardData.map((item) => (
                            <div className='business-cards' data-aos="zoom-in">
                                <div className='image'>
                                    <img src={item.image} />
                                </div>
                                <div className='content'>
                                    <p>
                                        {item.content}
                                    </p>
                                </div>
                                <div className='button'>
                                    <button onClick={() => navigate("/products")}>
                                        {item.button}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Stack>

            {/* MOBILE */}
            <Stack sx={{ display: { sm: "block", xs: "block", lg: "none", md: "none", } }} p={1}>
                <div style={{ "display": "flex", "justifyContent": "center", "marginTop": "50px", "flexDirection": "column", "alignItems": "center", "gap": "20px", "paddingBottom": "200px", }} >
                    <div style={{ "display": "flex", "flexDirection": "column", "gap": "5px", "maxWidth": "670px", "alignItems": "center", "textAlign": "center" }}>
                        <h4 style={{ "fontWeight": "700", "fontSize": "18px", "lineHeight": "27px", "display": "flex", "alignItems": "center", "textAlign": "center", "textTransform": "uppercase", "color": "#FF0558" }}>Our business</h4>
                        <h2 style={{ "fontStyle": "normal", "fontWeight": "700", "fontSize": "34px", "lineHeight": "40px", }}>Meet your expectations</h2>
                        <p style={{
                            "fontWeight": "400",
                            "fontSize": "14px",
                            "lineHeight": "25px",
                            width: "270px"
                        }}>We develop and produce private labels, our own brands and offer companies customized solutions from simple contract filling to full service.</p>
                    </div>
                    <div className='business-card'>
                        {cardData.map((item) => (
                            <div className='business-cards'>
                                <div className='image'>
                                    <img src={item.image} />
                                </div>
                                <div className='content'>
                                    <p>
                                        {item.content}
                                    </p>
                                </div>
                                <div className='button'>
                                    <button onClick={() => navigate("/products")}>
                                        {item.button}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Stack>
        </>
    )
}

export default Business