import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./Opportunities.module.css";

function Opportunities() {
    const navigate = useNavigate();
    return (
        <>
            <div className={styles.opportunities}>
                <img src='/assets/images/lab.png' />

                <div className={styles.opportunity}>
                    <div className={styles.header}>
                        <h1>Latest job opportunities</h1>
                        <span>As a rapidly growing company, we regularly offer you new opportunities to advance your career and take on new challenges.
                        </span>
                    </div>

                    <div className={styles.card}>
                        <h3>SOPRONEM NEMOURS</h3>
                        <p>As part of its continuous improvement approach in the HSE (Health, Safety and Environment) field, SOPRONEM is looking for a student intern or work-study student enrolled in a QHSE or HSE program. If you are interested, please fill out the Contact form.</p>
                        <button onClick={() => navigate("/contact")}>CONTACT US</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Opportunities