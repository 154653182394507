import React from 'react'
import "./Competences.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
function Competences() {
    const navigate = useNavigate();

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,

        nextArrow: <img src='/assets/images/div.png' />,
        prevArrow: <img src='/assets/images/div-1.png' />
    };


    return (
        <>
            <Stack sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>

                <div className='competences-section'>
                    <div className='competences-header'>
                        <h4>COMPETENCES</h4>
                        <h2>Our expertise</h2>
                        <p>Through continuous pioneering innovation, GoodFamily has built a solid reputation based on flexible production, high quality, expert R&D and safe products. Requirements that meet consumer expectations.</p>
                    </div>

                    <div className='slider-sec' style={{ width: "70%" }}>

                        <Slider {...settings}>
                            <div>
                                <div className='swiper-seciton'>
                                    <div className='content'>
                                        <img src='/assets/images/Mask group.png' />
                                    </div>

                                </div>
                                <div className='competences-footer'>
                                    <h2>Research & Development</h2>
                                    <button onClick={() => navigate("/aboutus")}>DISCOVER MORE</button>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </Stack>

            {/* MOBILE */}
            <Stack sx={{ display: { lg: "none", md: "none", sm: "block", xs: "block" } }}>

                <div className='competences-section'>
                    <div style={{
                        "display": "flex",
                        "flexDirection": "column",
                        "maxWidth": "670px",
                        "alignItems": "center",
                        "textAlign": "center",
                        gap: "20px",
                        padding: "0 15px"
                    }}>
                        <h4 style={{
                            "fontStyle": "normal",
                            "fontWeight": "700",
                            "fontSize": "18px",
                            "lineHeight": "25px",
                            "display": "flex",
                            "alignItems": "center",
                            "textAlign": "center",
                            "textTransform": "uppercase",
                            "color": "#ff0558"
                        }}>COMPETENCES</h4>
                        <h2 style={{
                            "fontStyle": "normal",
                            "fontWeight": "700",
                            "fontSize": "34px",
                            "lineHeight": "40px",
                            "color": "#3d4f59"
                        }}> Our expertise</h2>
                        <p style={{
                            "maxWidth": "630px",
                            "fontStyle": "normal",
                            "fontWeight": "400",
                            "fontSize": "14px",
                            "lineHeight": "25px"
                        }}>Through continuous pioneering innovation, GoodFamily has built a solid reputation based on flexible production, high quality, expert R&D and safe products. Requirements that meet consumer expectations.</p>
                    </div>

                    <div className='slider-sec' style={{ width: "70%" }}>

                        <Slider {...settings}>
                            <div>
                                <div style={{
                                    "display": "flex",
                                    "flexDirection": "row",
                                    "alignItems": "center",
                                    "justifyContent": "center"
                                }}>
                                    <div style={{
                                        "backgroundImage": "url('/assets/images/div_after.jpg')",
                                        "backgroundSize": "cover",
                                        "backgroundPosition": "center",
                                        "backgroundRepeat": "no-repeat",
                                        "width": "180px",
                                        "height": "180px",
                                        "display": "flex",
                                        "alignItems": "center",
                                        "justifyContent": "center"
                                    }}>
                                        <img style={{ width: "80px" }} src='/assets/images/Mask group.png' />
                                    </div>

                                </div>
                                <div style={{
                                    "display": "flex",
                                    "flexDirection": "column",
                                    "alignItems": "center",
                                    "gap": "10px",
                                    "marginTop": "10px"
                                }}>
                                    <h2 style={{
                                        "fontStyle": "normal",
                                        "fontWeight": "400",
                                        "fontSize": "20px",
                                        "lineHeight": "40px"
                                    }}>Research & Development</h2>
                                    <button style={{
                                        "width": "205.38px",
                                        "height": "42.8px",
                                        "background": "#ff0558",
                                        "borderRadius": "22px",
                                        "color": "white",
                                        "fontStyle": "normal",
                                        "fontWeight": "600",
                                        "fontSize": "13px",
                                        "lineHeight": "17px",
                                        "border": "none",
                                        "cursor": "pointer"
                                    }}>DISCOVER MORE</button>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </Stack>
        </>
    )
}

export default Competences