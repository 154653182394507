import React from 'react'
import styles from "./BusinessHome.module.css"
function BusinessHome() {
    return (
        <>
            <div className={styles.ourBusiness}>
                <div className={styles.business}>
                    <h3>OUR BUSINESS</h3>
                    <h1>Your partner for liquid detergents and household cleaning products.</h1>
                    <span>
                        SOPRONEM is one of the leading manufacturers of liquid detergents in Europe, with highly automated, flexible machines that can adapt to any product need. We do not only offer our customers a wide range of products, we also guarantee them permanent commercial and logistical support through a single point of contact.
                    </span>
                    <div className={styles.images}>
                        <img src='/assets/images/detergentGroup.svg' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessHome