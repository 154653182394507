import React, { useEffect } from 'react'
import Navbar from '../../components/header/Navbar'
import BannerContent from '../../components/bannerContent/BannerContent'
import styles from './CareerPage.module.css'
import CareerWithUs from '../../components/careerWithUs/CareerWithUs'
import Opportunities from '../../components/opprtunities/Opportunities'
function CareerPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Navbar />
            <div className={styles.career}>
                <BannerContent mainHead={"CAREER"} heading={"Work for Goodfamily"} details={"Join the SOPRONEM team. Challenge yourself, develop on a personal level and bring your talents and dedication to an ambitious and productive team."} />
            </div>
            <CareerWithUs />
            <Opportunities />
        </>
    )
}

export default CareerPage