import React from 'react'
import Download from "../../download/Download"
import styles from "./Sustainablity.module.css"

function Sustainablity() {
    return (
        <>
            <div className={styles.main}>
                <div className={styles.header}>
                    <h3>SASTAINABILTY</h3>
                    <h1>Sastainabilty now and into the future</h1>
                    <span>We have recognised our responsibility towards society, our customers, our employees and the environment. We understand sustainability as an ongoing process in which we align our activities as a manufacturer of liquid detergents, cleaning agents and cleaning products with the principle of sustainability. Environmental protection is therefore an integral part of our corporate culture, our business processes and our strategic orientation.</span>
                </div>

                <div className={styles.details}>
                    <div className={styles.content}>
                        <img src='/assets/shapes/pointer.png' />
                        <div className={styles.para}>
                            <p>We use more and more natural raw materials, including certified palm oil. The water used for the production comes from a drilling to avoid to use the drinking water. We have our bottles made directly on site to avoid any additional transport.</p>
                            <p>We offer our customers sustainable quality, modern innovation, high flexibility and professional service. Our strategy is to establish a customer relationship with quality, sustainability and delivery reliability that you can count on.</p>
                            <p>We have not only implemented a certified environmental management system, but also joined the most important industry associations at an early stage, to consciously communicate our dedication to sustainability to the outside world. Our contacts, cooperations and memberships such as A.I.S.E. contribute significantly to the development of sustainable products and business processes.</p>
                        </div>
                    </div>
                </div>

                <div className={styles.download}>

                    <Download heading={"ENVIRONMENTAL DECLARATION"} />
                </div>

            </div>
        </>
    )
}

export default Sustainablity