import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import aos from "aos"
import "aos/dist/aos.css"
import styles from './ProductRange.module.css'
function ProductRange() {
    useEffect(() => {
        aos.init({ duration: 1000 })
    }, [])
    const data = [
        {
            img: "/assets/shapes/Group 15.png",
            heading: "Laundry detergents",
            details: "Our range of detergents meets all consumer requirements: colour and heavy-duty detergents as well as various detergents for special textiles. Furthermore, out of consideration for our environment, we offer many certified and eco-labelled references. Our goal is to contribute to a better and healthier environment.",
            aos: "zoom-in-right"
        },
        {
            img: "/assets/shapes/Group 14.png",
            heading: "Manual dishwash",
            details: "Our wealth of experience in the development and manufacturing of rinse aids and dishwashing products enable us to offer powerful and efficient products that guarantee perfect results and streak-free shine.",
            aos: "zoom-in-left"
        },
        {
            img: "/assets/shapes/Group 16 (1).png",
            heading: "Laundry care",
            details: "Our fabric softeners have been developed to gently care for your textiles and give them a wonderful fragrance and long-lasting freshness. Our innovative formulas allow us to offer the best performance.",
            aos: "zoom-in-right"
        },
        {
            img: "/assets/shapes/Group 17.png",
            heading: "Household Cleaners",
            details: "To meet consumer needs, we have developed a complete range of cleaning products that can be used to clean all surfaces in the home - from windows to showers and toilets to floors.",
            aos: "zoom-in-left"
        },
        {
            img: "/assets/shapes/Group 18.png",
            heading: "Hydroalcoolic gel and solution",
            details: "Our hydroalcoolic gel and solution are skin sanitizing lotions. They are used without water. Their disinfectant function effectively helps stop the transmission of bacteria and viruses. SOPRONEM offers formulas certified according to EN 14476 (virucidal), EN 13727 (bactericidal) and EN 13624 (fungicidal) standards, which guarantee perfect hand hygiene and are an essential complement to barrier gestures. Use biocidal products with care. Before use, read the label and the information about the product",
            aos: "zoom-out-up"
        },
    ]
    return (
        <>
            <div className={styles.productRange}>
                <div className={styles.header}>
                    <h3>PRODUCT RANGE</h3>
                    <h1>Our core categories</h1>
                    <span>We understand the needs and requirements of end consumers and use this understanding to develop and manufacture products that make their daily lives easier.</span>
                </div>

                <div className={styles.cards}>
                    <Grid container spacing={5} direction="row"
                        justifyContent="center"
                        alignItems="flex-start">
                        {
                            data.map((item) => (
                                <Grid item md={6} >
                                    <div className={styles.card} >
                                        <img src={item.img} />
                                        <h1>{item.heading}</h1>
                                        <span>{item.details}</span>
                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default ProductRange