import React from 'react'
import { Stack } from '@mui/material'
import "./Home.css";

function Home() {
    return (
        <>
            <Stack sx={{ display: { xs: "none", sm: "none", md: "block" }, backgroundImage: "url('/assets/images/toppng 1.png')", height: "76.5vh", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "bottom right", width: "100%", }}>
                <div className='banner'>
                    <div className='banner-head'>
                        <h3>Welcome to</h3>
                        <h2>Goodfamily</h2>
                    </div>
                    <div className='banner-content'>
                        <p>
                            We are specialists in the production of liquid detergents and household cleaners. For several decades we have been putting our knowledge and experience at the service of the biggest India  brands and trademarks.
                        </p>
                    </div>
                    <div className='banner-btn'>
                        <button>
                            DISCOVER MORE
                        </button>
                    </div>
                </div>
            </Stack>

            {/* MOBILE */}

            <Stack sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" }, width: "100%", }} >
                <div style={{ "display": "flex", "alignItems": "center", "flexDirection": "column", "gap": "30px", padding: "40px 15px 0px 15px" }}>
                    <div>
                        <h3 style={{ "fontWeight": "500", "fontSize": "33px", "lineHeight": "40px" }}>Welcome to</h3>
                        <h2 style={{ "fontWeight": "700", "fontSize": "34px", "lineHeight": "40px" }}>Goodfamily</h2>
                    </div>
                    <div >
                        <p style={{ "minWidth": "300px", maxWidth: "550px", "fontStyle": "normal", "fontWeight": "400", "fontSize": "14px", "lineHeight": "25px", "textAlign": "center" }}>
                            We are specialists in the production of liquid detergents and household cleaners. For several decades we have been putting our knowledge and experience at the service of the biggest India  brands and trademarks.
                        </p>
                    </div>
                    <button style={{ "width": "205.38px", "height": "42.8px", "border": "none", "background": "#ff0558", "borderRadius": "22px", "color": "white", "fontWeight": "600", "fontSize": "13px", "lineHeight": "17px", "cursor": "pointer" }}>
                        DISCOVER MORE
                    </button>
                    <Stack sx={{ width: { xs: "309px", sm: "550px" } }}>
                        <img src='/assets/images/toppng 1.png' />

                    </Stack>
                </div>
            </Stack>
        </>
    )
}

export default Home;