import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./GlobalReach.css"
function GlobalReach() {
    const navigate = useNavigate();
    return (
        <>
            <div className='global-section'>
                <div className='global-content'>
                    <h3>
                        Global reach
                    </h3>
                    <h1>
                        Goodfamily gives you a logistical advantage
                    </h1>
                    <p>
                        With our production site based in France (Saint-Pierre-les-Nemours), you will benefit from our logistics capacity throughout India.
                    </p>
                    <button onClick={() => navigate("/business")}>
                        DISCOVER MORE
                    </button>
                </div>
            </div>
        </>
    )
}

export default GlobalReach