import React from 'react'
import styles from './CompanyProfile.module.css'
function CompanyProfile() {
    return (
        <>
            <div className={styles.companyProfile}>
                <div className={styles.content}>
                    <h3>COMPANY PROFILE</h3>
                    <h1>Over 100 years of know-how</h1>
                    <span>For more than a century, SOPRONEM has been constantly working on innovations to make consumers' lives cleaner and easier. Today, our company is one of the leading European industrial companies specialised in the production of liquid detergents, fabric softeners, dishwashing detergents and multi-purpose detergents. Our excellent partnerships in the field of detergents and cleaning agents enable us to offer our customers a wide and creative range of products.</span>
                    <img src='/assets/logo/logoCircle.png' />
                </div>
            </div>
        </>
    )
}

export default CompanyProfile