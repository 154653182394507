import React from 'react'
import styles from "./CompanyDetails.module.css"
function CompanyDetails() {
    return (
        <>
            <div className={styles.details}>
                <div className={styles.content}>
                    <h3>SOPRONEM NEMOURS</h3>
                    <span>Our company is located at the heart of a major motorway junction and occupies a strategic position just 80 km from Paris. Our logistics department is outsourced and specialises in mass distribution.
                        By integrating EDI, we ensure the rapid exchange of information with our customers and our logistics partner, increase transparency and sustainably reduce the error rate.</span>

                    <div className={styles.cards}>
                        <div className={styles.first}>
                            <div >
                                <h1>100</h1>
                                <h5>years of experience</h5>
                            </div>
                            <div>
                                <h1>150k</h1>
                                <h5>tons per year</h5>
                            </div>

                        </div>

                        <div className={styles.last}>
                            <div>
                                <h1>116</h1>
                                <h5>Employees</h5>
                            </div>
                            <div>
                                <h1>200</h1>
                                <h5>references manufactured</h5>
                            </div>

                        </div>
                    </div>
                    <img src='/assets/images/factory.png' />
                </div>
            </div>
        </>
    )
}

export default CompanyDetails