import React from 'react'
import styles from './Download.module.css'
function Download({ heading }) {
    return (
        <>
            <div className={styles.download}>
                <div className={styles.content}>
                    <img src='/assets/logo/download.png' />
                    <h2>{heading}</h2>
                    <button>Download PDF</button>
                </div>
            </div>
        </>
    )
}

export default Download