import React from 'react'
import styles from "./NewsContent.module.css"
function NewsContent() {
    return (
        <>
            <div className={styles.content}>
                <div className={styles.header}>
                    <h3>NEWS</h3>
                    <h1>What is new at Goodfamily?</h1>
                </div>

                <div className={styles.cards}>
                    <div className={styles.card}>
                        <img src='/assets/images/newsCard1.svg' />
                        <h1>SOPRONEM and its “Air intérieur contrôlé” approach (Indoor Air Controlled)</h1>
                        <span>Numerous studies show that the indoor air we breathe, polluted by all kinds of substances, accelerates the health risks for humans.</span>
                        <p>10.5.2020</p>
                    </div>
                    <div className={styles.card}>
                        <img src='/assets/images/newsCard2.svg' />
                        <h1>Hydroalcoholic gel massively distributed in 40 retirement homes in Seine-et-Marne</h1>
                        <span>This is an unusual mission for the department´s agents. Yesterday, the county council distributed 1,000 litres of hydroalcoholic gel.
                        </span>
                        <p>10.5.2020</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewsContent