import React from 'react'
import styles from "./ContactForm.module.css"
function ContactForm() {
    return (
        <>
            <div className={styles.contact}>
                <div className={styles.wrapper}>
                    <form>
                        <input type={"text"} placeholder='Your Name' />
                        <input type={"text"} placeholder='Your Surname' />
                        <input type={"text"} placeholder='Your Email *' required />
                        <input type={"text"} placeholder='Your Subject' />
                        <textarea placeholder='Message *' className={styles.textArea} required />
                        <button>SEND A MESSAGE</button>
                    </form>

                    <div className={styles.location}>
                        <img src='/assets/shapes/locationVector.svg' />
                        <h3>Goodfamily NEMOURS</h3>
                        <span>2 rue des EtangsF-77140 - Saint‐Pierre‐Lès-Nemours+33 (0)1 64 45 51 20info.fr@sopronem.com</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactForm