import React, { useEffect } from 'react'
import AreaTabs from '../../components/businessComponents/areaTabs/AreaTabs'
import BusinessContent from '../../components/businessComponents/businessContent/BusinessContent'
import BusinessDiscover from '../../components/businessComponents/businessContentDiscover/BusinessDiscover'
import BusinessHome from '../../components/businessComponents/BusinessHome/BusinessHome'
import Navbar from '../../components/header/Navbar'
import "./BusinessPage.css"
function BusinessPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <div className='business'>
                <Navbar />
                <BusinessHome />
            </div>
            <div className='business-page-details'>
                <BusinessContent />
                <BusinessDiscover />
            </div>
            <AreaTabs />
        </>
    )
}

export default BusinessPage