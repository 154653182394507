import { useRoutes } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/Footer';
import Router from './Router';
function App() {
  return (
    <>
      <Router />
      <Footer />
    </>
  )
}

export default App;
