import React from 'react'
import styles from "./NewsBanner.module.css"
function NewsBanner() {
    return (
        <div className={styles.bannerContent}>
            <h3>LATEST ARTICLE</h3>
            <h1>Goodfamily is committed to ESAT – Les ateliers caravelle</h1>
        </div>
    )
}

export default NewsBanner