import React, { useEffect } from 'react'
import Navbar from "../../components/header/Navbar"
import BannerContent from "../../components/bannerContent/BannerContent"
import styles from "./ProductsPage.module.css"
import ProductRange from '../../components/productRange/ProductRange'
function ProductsPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <div className={styles.productsPage}>
                <Navbar />
                <div className={styles.banner}>
                    <BannerContent mainHead={"PRODUCTS"} heading={"Laundry and home care"} details={"In close cooperation with our customers we develop and manufacture products that ensure the satisfaction of the end users and thus their own success."} />
                </div>
                <ProductRange />
            </div>
        </>
    )
}

export default ProductsPage