import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from "./BusinessDiscover.module.css"
function BusinessDiscover() {
    const navigate = useNavigate();
    return (
        <>
            <div className={styles.discover}>
                <div className={styles.discoverContent}>
                    <img src='/assets/shapes/groupofBottle.svg' />
                    <h1>Discover how we have developed our "green" product range.</h1>
                    <span>The destruction of our natural habitats, increasing environmental pollution, health risks from dangerous pollutants, animal welfare and also social aspects are factors that have significantly motivated us and finally led to this project.
                    </span>
                    <button onClick={() => navigate("/aboutus")}>DISCOVER MORE</button>
                </div>
            </div>
        </>
    )
}

export default BusinessDiscover